body {
    background: #f9f9f9;
    color: black;
    font-family: 'Roboto', sans-serif;
    /* Utilisation de la police Roboto */
}

.CarrouselDiv {
    max-width: 60%;
    margin-left: 20%;
}

.pricing {
    max-width: 20%;
    margin: 0 auto;
    text-align: center;
    padding: 40px;
    background-color: #f9f9f9;
}

.pricing h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
    /* Couleur du texte pour le titre */
}

.pricing h2 {
    font-size: 1.5rem;
    margin-bottom: 30px;
    color: #666;
    /* Couleur du texte pour le sous-titre */
}

.pricing-plans {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.pricing-plan {
    width: 300px;
    padding: 20px;
    margin: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.pricing-plan h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
    /* Couleur du texte pour le titre du plan */
}

.pricing-plan p {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #666;
    /* Couleur du texte pour la description du plan */
}

.pricing-plan .home__button {
    background-color: #A6B1F7;
    color: #fff;
    border: 1px solid #A6B1F7;
    border-radius: 20px;
    padding: 10px 20px;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}

.pricing-plan .home__button:hover {
    background-color: #919EF6;
    border: 1px solid #919EF6;
}

/* Style du carousel */
.carousel {
    max-width: 100%;
    /* Ajustez la largeur maximale du carousel selon vos besoins */
    margin: 0 auto;
    /* Centre le carousel horizontalement */
    /* border: 1px solid #ccc; */
    /* Ajoute une bordure autour du carousel */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    /* Ajoute une légère ombre au carousel */
    border-radius: 20px;
    /* Arrondit les coins du carousel */
}

/* Style des images dans le carousel */
.carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    /* Ajoute un fond clair à chaque diapositive */
    padding: 20px;
    /* Ajoute un espace intérieur pour les diapositives */


}

.carousel .slide img {
    max-width: 100%;
    /* Redimensionne les images pour s'adapter à la largeur de leur conteneur */
    height: auto;
    /* Ajuste la hauteur en conséquence pour maintenir les proportions */
    border-radius: 8px;
    /* Arrondit les coins des images */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    /* Ajoute une légère ombre aux images */
}

.PrincipalDiv {
    text-align: center;
    /* Centre le texte horizontalement dans le conteneur */
    max-width: 100%;
}

.SectionsFeatures img {
    margin-top: 10px;
    width: 300px;
    /* Ajustez la taille selon vos besoins */
    border-radius: 20px;
    margin-bottom: 10px;

}

.SectionsFeatures {
    margin-top: 50px;
    list-style-type: none;
    margin-bottom: 50px;
    font-size: 1.5rem;
    color: #333;
    font-weight: bold;
    /* Ajoutez cette ligne pour mettre le texte en gras */
}

.PrincipalDiv h1 {
    margin-top: 40px;
    /* Marge en haut du titre pour l'éloigner du haut */
}

.home__buttons {
    display: flex;
    justify-content: center;
    /* Centrer les boutons horizontalement */

}

.home__button {
    display: inline-block;
    padding: 10px 20px;
    margin: 5px;
    text-decoration: none;
    color: #fff;
    background-color: #A6B1F7;
    border: 1px solid #A6B1F7;
    border-radius: 20px;
    transition: background-color 0.3s, color 0.3s;

}

.home__buttons__Pricing {
    display: inline-block;
    padding: 10px 20px;
    margin: 5px;
    text-decoration: none;
    color: #fff;
    background-color: #A6B1F7;
    border: 1px solid #A6B1F7;
    border-radius: 20px;
    transition: background-color 0.3s, color 0.3s;
}

.home__button__Pricing {
    text-decoration: none;
    color: #fff;
}

.home__button:hover {
    background-color: #919EF6;
    border: 1px solid #919EF6;
}

.home__buttons__Pricing:hover {
    background-color: #919EF6;
    border: 1px solid #919EF6;
}

/* .video-container {
    max-width: 70%;
    margin-left: 15%;
} */


.SectionsFeatures {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Trois colonnes égales par ligne */
    gap: 20px;
    /* Espacement entre les colonnes et les lignes */
    padding: 20px;
}

/* Style supplémentaire pour chaque élément à l'intérieur de la grille */
.SectionsFeatures div {
    text-align: center;
    /* Alignement du texte au centre */
}

video {
    max-width: 10%;
    border-radius: 80px;
}

header a {
    color: black;
    font-size: 20px;

}

header a:hover {
    color: #f9f9f9;
    font-size: 21px;

}

header {
    background-color: #A6B1F7;
    /* ... (vos styles actuels) */
    transition: background-color 0.3s ease;
    /* Ajoutez la transition que vous souhaitez */
    padding: 30px
}

/* Le style du header lorsque la classe "scrolled" est appliquée */
header.scrolled {
    background-color: #f9f9f9;
    /* Ajoutez la couleur de fond que vous souhaitez */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    /* Ajoutez une ombre si vous le souhaitez */
    /* ... (autres styles) */
}

/* Contact Container */
.contact-container {
    text-align: center;
    padding: 20px;
}

/* Titre "Contact Us" */
.contact-container h1 {
    font-size: 2rem;
    margin-bottom: 20px;
}

/* Paragraphe introductif */
.contact-container p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 30px;
}

/* Formulaire de contact */
.contact-form {
    background-color: #f9f9f9;
    border-radius: 15px;
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

/* Étiquettes du formulaire */
.form-label {
    display: block;
    font-size: 1rem;
    margin-bottom: 5px;
}

/* Champs de saisie du formulaire */
.form-input,
.form-textarea {
    width: 90%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
}

/* Bouton "Send Message" */
.form-submit {
    background-color: #A6B1F7;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Style au survol du bouton */
.form-submit:hover {
    background-color: #919EF6;
}

.Features {
    background-color: #A6B1F7;
    border: 2px solid #A6B1F7;
    border-radius: 15px;
    padding: 40px;
    font-size: 20px;
    max-width: 75%;
    color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* Centrer le texte à l'intérieur de la div */
    margin: 0 auto;
    /* Centrer la div elle-même */
}

.Features h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #fff;
}

.Features ul {
    padding: 0;
}

.Features li {
    margin-bottom: 15px;
    list-style-type: none;
    transition: background-color 0.3s, color 0.3s, padding 0.3s, transform 0.3s;

}


@media only screen and (max-width: 768px) {

    .CarrouselDiv,
    .pricing {
        max-width: 90%;
        margin-left: 5%;
    }

    .pricing h1 {
        font-size: 2rem;
    }

    .pricing h2 {
        font-size: 1.2rem;
    }

    .pricing-plan {
        width: calc(100% - 20px);
        margin: 10px;
    }

    .carousel {
        max-width: 100%;
        margin: 0;
    }

    .carousel .slide {
        padding: 10px;
    }

    .carousel .slide img {
        border-radius: 5px;
    }

    .PrincipalDiv h1 {
        margin-top: 20px;
        max-width: 100%;
    }

    .PrincipalDiv {

        max-width: 100%;
    }

    .home__buttons {
        flex-direction: column;
        max-width: 80%;
        margin-left: 10px;
    }

    .home__button {
        width: 100%;
    }

    .video-container {
        max-width: 100%;
        margin-left: 0;

    }

    video {
        max-width: 100%;
        background-color: transparent;
    }

    header {


        background-color: transparent;

    }

    header a {
        font-size: 15px;
    }

    .home__buttons__Pricing {
        flex-direction: column;
        max-width: 80%;
        margin-left: 10px;
        text-decoration: none;
    }

    .home__button__Pricing {
        text-decoration: none;
        color: #fff;
    }

    .SectionsFeatures {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        /* Trois colonnes égales par ligne */
        gap: 20px;
        /* Espacement entre les colonnes et les lignes */
        padding: 20px;

    }
}



.contact-container button {
    color: #A6B1F7;
    background-color: #fff;
    border: 3px solid #A6B1F7;
    border-radius: 20px;
    padding: 10px 20px;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}

.contact-container button:hover {
    background-color: #A6B1F7;
    color: #fff;
    border: 1px solid #A6B1F7;
    border-radius: 20px;
    padding: 10px 20px;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}