/* Styles pour le footer */
footer {
    background-color: #A6B1F7;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    /* position: fixed; */
    bottom: 0;
    /* Fixe le footer en bas de la fenêtre du navigateur */
    width: 100%;
    /* Utilise la largeur complète de la fenêtre */
}

/* Styles pour la navigation dans le footer */
nav {
    margin-top: 10px;
    /* Espacement depuis le haut du footer */
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    /* Centrez horizontalement les éléments du menu */
}

nav li {
    margin: 0 10px;
    /* Espacement entre les éléments du menu */
    display: inline;
    /* Affichez les éléments du menu en ligne */
}

nav li:last-child {
    margin-right: 0;
}

nav li:hover {
    cursor: pointer;
}

/* Styles pour les liens dans le footer */
nav a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
}

nav a:hover {
    color: black;
}